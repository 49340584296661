import {
    useMemo,
    useState,
    // useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import numberFormat from '../modules/number-format.mjs';

import moneyMaking from '../data/money-making.json';
import customMoneyMaking from '../data/custom-money-making.json';

import '../App.css';

function MoneyMaking({filter, playerStats, mapping}) {
    const [hideEmpty, setHideEmpty] = useState(true);
    const [hideUnqualified, setHideUnqualified] = useState(true);

    const rows = useMemo(() => {
        let returnRows = [];

        for (const result of moneyMaking) {
            const hourlyCost = result.hourlyInput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                if(!mapping[item.id]){
                    return acc;
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0);

            const hourlyProfit = result.hourlyOutput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                if(!mapping[item.id]){
                    return acc;
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0) - hourlyCost;

            if(hourlyProfit){
                result.hourlyProfitWiki = Math.floor(hourlyProfit);
                result.hourlyProfit = Math.floor(hourlyProfit);
            }

            returnRows.push({
                id: result.methodName,
                ...result,
            });
        }

        for(const result of customMoneyMaking){
            const hourlyCost = result.hourlyInput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0);

            const hourlyProfit = result.hourlyOutput.reduce((acc, item) => {
                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0) - hourlyCost;

            returnRows.push({
                id: result.methodName,
                hourlyProfit,
                ...result,
            });
        }

        returnRows = returnRows.filter((row) => {
            if(Object.keys(row.skills).length === 0 && hideEmpty){
                return false;
            }

            for (const skill in row.skills) {
                if (playerStats[skill] < row.skills[skill] && hideUnqualified) {
                    return false;
                }
            }

            if(filter && !row.methodName.toLowerCase().includes(filter.toLowerCase())){
                return false;
            }

            return true;
        });

        return returnRows;
    }, [playerStats, hideEmpty, hideUnqualified, filter, mapping]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'methodName',
            flex: 1,
            headerName: 'Name',
            renderCell: ({ value }) => {
                const itemData = rows.find((row) => row.methodName === value);
                return <a
                    href={`${itemData?.methodLink.includes('https') ? '' : 'https://oldschool.runescape.wiki'}${itemData?.methodLink}`}
                >
                    {value}
                </a>;
            },
            minWidth: 200,
        },
        {
            field: 'hourlyProfit',
            headerName: 'Profit / Hour',
            renderCell: ({ value }) => numberFormat(value),
        },
        // {
        //     field: 'hourlyProfitWiki',
        //     headerName: 'Profit / Hour',
        //     renderCell: ({ value }) => numberFormat(value),
        // },
        {
            field: 'skills',
            headerName: 'Skills',
            renderCell: ({ value }) => {
                const skillRequirements = [];
                for(const skill in value){
                    let isQualifiedClass = 'skill-ok';
                    if(playerStats[skill] < value[skill]){
                        isQualifiedClass = 'skill-not-ok';
                    }

                    skillRequirements.push(<div
                        key={skill}
                    >
                        <span
                            className={isQualifiedClass}
                        >
                            {skill}: {value[skill]}
                        </span>
                    </div>);
                };

                return <div
                    className='field-list-wrapper'
                >
                    {skillRequirements}
                </div>;
            },
            width: 200,
        },
        // {
        //     field: 'reward',
        //     headerName: 'Reward',
        //     valueFormatter: (value) => numberFormat(value),
        //     width: 120,
        // },
        // {
        //     field: 'profit',
        //     headerName: 'Profit',
        //     valueFormatter: (value) => numberFormat(value),
        //     width: 120,
        // },
    ];

    const calculateRowHeight = (params) => {
        return (Object.keys(params.model.skills).length || 1) * 25 + (16 * params.densityFactor);
    };

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <title>
            Money Making - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant='h1'
            >
                {`Money making`}
            </Typography>
            <FormGroup>
                <Stack
                    direction="row"
                >
                    <FormControlLabel control={
                        <Checkbox
                            checked={hideEmpty}
                            label="Hide empty requirements"
                            onChange={(event) => {
                                setHideEmpty(event.target.checked);
                            }}
                        />
                    } label="Hide empty" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={hideUnqualified}
                            label="Hide unqualified"
                            onChange={(event) => {
                                setHideUnqualified(event.target.checked);
                            }}
                        />
                    } label="Hide unqualified" />
                </Stack>
            </FormGroup>
            <DataGrid
                density="standard"
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [{
                            field: 'hourlyProfit',
                            sort: 'desc',
                        }],
                    },
                }}
                getRowHeight={calculateRowHeight}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />
        </Container>
    </Box>;
}

export default MoneyMaking;
